<template>
	<div class="home">
		<HelloWorld></HelloWorld>
		<!-- 轮播图 -->
		<div class="index_swiper" :style="imgStyle">
			<el-carousel trigger="click" height="700px">
				<el-carousel-item v-for="(item,index) in bannerList" :key="index">
					<img :style="imgStyle" :src="item.fengmian" alt="">
					<!-- <el-image :style="imgStyle" :src="item.fengmian" fit="fill"></el-image> -->
				</el-carousel-item>
			</el-carousel>
		</div>
		<div class="index_two">
			<img class="index_two_logo" src="../assets/ay7.png" alt="">
			<div class="index_two_content">
				<div class="index_two_content_list" v-for="(item,index) in productlist" :key="index"
					@click="gochanpin(item.aid,item.wenben)">
					<img :src="item.tupian" alt="">
					<span class="index_two_content_list_title">{{item.wenben}}</span>
				</div>
			</div>
		</div>
		<div class="index_three">
			<img class="index_two_logo" src="../assets/ay20.png" alt="">
			<div class="index_three_content">
				<div class="index_three_content_l">
					<div class="index_three_content_l_one" v-for="(item,index) in formulalist" v-if="index==0"
						@click="gopeifang(item.aid)">
						<img class="index_three_content_l_one_img" :src="item.tupian" alt="">
						<div class="index_three_content_l_one_mb">
							<p>{{item.wenben}}</p>
							<span>{{item.jianjie}}</span>
						</div>
					</div>
					<div class="index_three_content_l_two">
						<div class="index_three_content_l_two_list" v-for="(item,index) in formulalist"
							v-if="index==1 ||index==2" @click="gopeifang(item.aid)">
							<img :src="item.tupian" alt="">
							<h3>{{item.wenben}}</h3>
							<p>{{item.jianjie}}</p>
						</div>
					</div>
				</div>
				<div class="index_three_content_l" style="float: right;">
					<div class="index_three_content_l_two" style="margin-top: 0;">
						<div class="index_three_content_l_two_list" v-for="(item,index) in formulalist"
							v-if="index==3 ||index==4" @click="gopeifang(item.aid)">
							<img :src="item.tupian" alt="">
							<h3>{{item.wenben}}</h3>
							<p>{{item.jianjie}}</p>
						</div>
					</div>
					<div class="index_three_content_l_one" style="clear: both;margin-top: 35px;"
						v-for="(item,index) in formulalist" v-if="index==5" @click="gopeifang(item.aid)">
						<img class="index_three_content_l_one_img" :src="item.tupian" alt="">
						<div class="index_three_content_l_one_mb">
							<p>{{item.wenben}}</p>
							<span>{{item.jianjie}}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 关于奥源 -->
		<div class="index_four">
			<img class="index_two_logo" src="../assets/ays1.png" alt="">
			<div class="index_four_content">
				<div class="index_four_content_l">
					<h1>{{companysj.title}}</h1>
					<!-- <p>since {{companysj.create_time}}</p> -->
					<div class="index_four_content_l_border"></div>
					<span>{{companysj.jianshu}}</span>
					<div class="index_four_content_l_more" @click="gocompany">
						<span>查看更多 More ></span>
					</div>
				</div>
				<div class="index_four_content_r">
					<img :src="companysj.fengmian" alt="">
				</div>
			</div>
		</div>
		<!-- 最新资讯 -->
		<div class="index_five">
			<img class="index_two_logo" src="../assets/ay26.png" alt="">
			<div class="index_five_content">
				<div class="index_five_l">
					<div class="index_five_l_content" style="position: relative;">
						<img style="width: 30px;height: 30px;position: absolute;right: 0;top: 100px;z-index: 999;"
							src="../assets/ays2.png" @click="arrowClick('prev')">
						<img style="width: 30px;height: 30px;position: absolute;left: 0;top: 100px;z-index: 999;transform: rotate(180deg);"
							src="../assets/ays2.png" @click="arrowClick('next')">
						<el-carousel trigger="click" height="662px" arrow="never" ref="cardShow">
							<el-carousel-item v-for="(item,index) in newsbanner" :key="index">
								<div class="index_five_l_content_list"
									@click.stop="gonewsdetail(item.aid,item.tiaozhuanlianjie)">
									<img class="index_five_l_content_list_img" :src="item.wenben_15206" alt="">
									<h3>{{item.wenben}}</h3>
									<span>{{item.create_time}}</span>
									<p>{{item.wenben_15210}}</p>
									<div class="index_five_l_content_list_bottom">
										<img src="../../src/assets/ay28.png" alt="">
										<div class="index_five_l_content_list_bottom_r">
											<div class="index_five_l_content_list_bottom_r_list"
												:class="{'index_five_l_content_list_bottom_r_list_now':index==newsindex}"
												v-for="(news,newsindex) in 3" :key="newsindex"></div>
										</div>
									</div>
								</div>
							</el-carousel-item>
						</el-carousel>
					</div>
				</div>
				<div class="index_five_r">
					<div class="index_five_r_header">
						<span>近期新闻</span>
						<div class="index_five_r_header_border"></div>
					</div>
					<div class="index_five_r_list" v-for="(item,index) in newsrightlist" :key="index"
						@click="gonewsdetail(item.aid,item.tiaozhuanlianjie)">
						<div class="index_five_r_list_l">
							<p>{{item.create_time_text}}</p>
							<span>{{item.year}}</span>
						</div>
						<div class="index_five_r_list_r">
							<p>{{item.wenben}}</p>
							<span>{{item.wenben_15210}}</span>
						</div>
					</div>
				</div>
			</div>
			<div style="width: 100%;height: 110px;clear: both;"></div>
		</div>
		<!-- 代理品牌 -->
		<div class="index_six">
			<img class="index_two_logo" src="../assets/ay70.png" alt="">
			<div class="index_six_content">
				<img class="index_six_list" :src="item.tupian" alt="" v-for="item in agentlist"
					@click="agentdetail(item.aid)">
				<!-- <el-image class="index_six_list" :src="item.tupian" :fit="contain"  v-for="item in agentlist"></el-image> -->
			</div>
			<div class="index_six_more" @click="moredailipingpai">
				<span>查看更多 More ></span>
			</div>
		</div>
		<footerBottom></footerBottom>
	</div>
</template>

<script>
	import HelloWorld from '@/components/HelloWorld.vue';
	import footerBottom from '@/components/footerBottom.vue';
	export default {
		name: 'HomeView',
		components: {
			HelloWorld,
			footerBottom,
		},
		data() {
			return {
				bannerList: [], //轮播图
				productlist: [], //原料推荐
				formulalist: [], //配方推荐
				companysj: "", //关于奥源
				newsbanner: [], //新闻资讯轮播
				newsrightlist: [], //新闻资讯右边部分
				agentlist: [], //获取代理品牌数据
				clientWidth: 0,
			}
		},
		create() {},
		mounted() {
			this.get_banner(); //首页轮播
			this.get_productcate(); //获取原料推荐
			this.get_formulalist(); //获取配方列表
			this.get_operationsystemarticle(); //获取关于奥源
			this.get_news(); //新闻资讯
			this.get_agent(); //获取代理品牌
		},
		computed: {
			imgStyle() {
				let width = this.clientWidth;
				console.log(width)
				if (width < 640) {
					return {
						width: '100%',
						height: '250px'
					}
				} else if (width >= 640 && width < 1024) {
					return {
						width: '100%',
						height: '300px'
					}
				} else if (width >= 1024 && width < 1524) {
					return {
						width: '100%',
						height: '450px'
					}
				} else {
					return {
						width: '100%',
						height: '700px'
					}
				}
			}
		},
		watch: {
			'$el': {
				handler() {
					this.clientWidth = document.documentElement.clientWidth;
				},
				immediate: true
			}
		},
		methods: {
			//获取首页轮播数据
			get_banner() {
				this.$http({
					url: '647d8ec4ef38f',
					method: 'get',
				}).then((res) => {
					if (res.data.code == 1) {
						this.bannerList = res.data.data;
					}
				});
			},

			arrowClick(val) {
				if (val === 'next') {
					this.$refs.cardShow.next()
				} else {
					this.$refs.cardShow.prev()
				}
			},


			//获取原料推荐
			get_productcate() {
				this.$http({
					url: '648027ffd08a9',
					method: 'get',
					params: {
						is_tree: 0,
						type: 2,
					}
				}).then((res) => {
					if (res.data.code == 1) {
						this.productlist = res.data.data;
					}
				});
			},

			//轮播图跳转
			swiperclick(url) {
				window.location.href = url;
			},

			//跳转产品模块
			gochanpin(aid,name) {
				this.$router.push({
					path: "chanpinzhongxin",
					query: {
						erjiid: aid,
						nametext:name
					}
				})
			},

			//获取配方推荐列表
			get_formulalist() {
				this.$http({
					url: '64802bbde6a7f',
					method: 'get',
					params: {
						// type:"2",
						// list_rows:"6",
						// page:1,
					},
				}).then((res) => {
					if (res.data.code == 1) {
						this.formulalist = res.data.data;
					}
				});
			},

			//跳转配方主页
			gopeifang(aid) {
				console.log(aid, 1111)
				localStorage.setItem("headerindex", 4);
				this.$router.push({
					path: "peifanganli",
					query: {
						aid: aid
					}
				})
			},

			//获取关于奥源
			get_operationsystemarticle() {
				this.$http({
					url: '636c78ae2216b',
					method: 'post',
					data: JSON.stringify({
						aid: "8",
					})
				}).then((res) => {
					if (res.data.code == 1) {
						this.companysj = res.data.data;
					}
				});
			},

			//查看更多的关于公司
			gocompany() {
				localStorage.setItem("headerindex", 2)
				this.$router.push({
					path: "aboutus"
				})
			},

			//获取新闻列表
			get_news() {
				this.$http({
					url: '647d91b1de427',
					method: 'post',
					data: JSON.stringify({
						page: 1,
						list_rows: 8,
					})
				}).then((res) => {
					if (res.data.code == 1) {
						this.newsbanner = res.data.data.data.slice(0, 3);
						this.newsrightlist = res.data.data.data.slice(3, 6);
					}
				});
			},

			//跳转新闻详情
			gonewsdetail(aid, url) {
				localStorage.setItem("headerindex", 6);
				if (url == "") {
					this.$router.push({
						path: "newsdetail",
						query: {
							aid: aid
						}
					})
				} else {
					window.open(url);
				}
			},

			//获取代理品牌
			get_agent() {
				this.$http({
					url: '6480312105120',
					method: 'post',
					data: JSON.stringify({
						type: "2",
					})
				}).then((res) => {
					if (res.data.code == 1) {
						this.agentlist = res.data.data.data;
					}
				});
			},

			//跳转品牌详情详情
			agentdetail(aid) {
				localStorage.setItem("headerindex", 5);
				this.$router.push({
					path: "dailipingpaidetail",
					query: {
						aid: aid
					}
				})
			},

			//查看更多的代理品牌
			moredailipingpai() {
				localStorage.setItem("headerindex", 5);
				this.$router.push({
					path: "dailipingpai"
				})
			},
		}
	}
</script>
<style scoped>
	.home {
		width: 100%;
		background-color: #fff;
		overflow: hidden;
	}

	.index_swiper {
		width: 100%;
		overflow: hidden;
		clear: both;
	}

	.index_swiper img {
		width: 100%;
		height: 100%;
	}

	.index_two {
		width: 100%;
		overflow: hidden;
		background-color: #F9FAFA;
	}

	.index_two_logo {
		width: 273px;
		height: 98px;
		display: block;
		margin: 76px auto;
	}

	.index_two_content {
		width: 1200px;
		overflow: hidden;
		margin: 0 auto 46px auto;
	}

	.index_two_content_list {
		width: 185px;
		height: 210px;
		float: left;
		background-color: #fff;
		text-align: center;
		margin-right: 15px;
		margin-bottom: 25px;
	}

	.index_two_content_list:nth-child(6n) {
		margin-right: 0;
	}

	.index_two_content_list:hover {
		transform: scale(1.1);
		cursor: pointer;
	}

	.index_two_content_list img {
		width: 88px;
		height: 88px;
		display: block;
		margin: 30px auto 30px auto;
		bottom: 1px dashed #eee;
	}

	.index_two_content_list_title {
		font-size: 18px;
		font-weight: 400;
		color: #111111;
		display: block;
	}

	.index_two_content_list_ms {
		width: 168px;
		height: 43px;
		font-size: 14px;
		font-weight: 400;
		color: #666666;
		line-height: 24px;
		display: block;
		margin: 16px auto;
	}

	.index_three {
		width: 100%;
		overflow: hidden;
		background-color: #fff;
		clear: both;
	}

	.index_three .index_two_logo {
		width: 278px;
		height: 98px;
	}

	.index_three_content {
		width: 1200px;
		overflow: hidden;
		margin: 0 auto 120px auto;
	}

	.index_three_content_l {
		width: 584px;
		overflow: hidden;
		float: left;
	}

	.index_three_content_l_one {
		width: 584px;
		height: 584px;
		position: relative;
	}

	.index_three_content_l_one:hover {
		cursor: pointer;
	}

	.index_three_content_l_one_img {
		width: 100%;
		height: 100%;
		border-radius: 8px;
	}

	.index_three_content_l_one_mb {
		width: 504px;
		height: 96px;
		background-color: rgba(0, 0, 0, 0.2);
		position: absolute;
		left: 40px;
		bottom: 32px;
		text-indent: 32px;
		border-radius: 8px;
	}

	.index_three_content_l_one_mb p {
		font-size: 20px;
		font-weight: bold;
		color: #FFFFFF;
		margin-top: 19px;
	}

	.index_three_content_l_one_mb span {
		font-size: 16px;
		font-weight: 400;
		color: #FFFFFF;
		margin-top: 12px;
		margin-left: 32px;
	}

	.index_three_content_l_one_mb_btn {
		width: 28px;
		height: 28px;
		background-color: #fff;
		border-radius: 50%;
	}

	.index_three_content_l_two {
		width: 100%;
		overflow: hidden;
		margin-top: 40px;
	}

	.index_three_content_l_two_list {
		width: 280px;
		overflow: hidden;
		text-indent: 32px;
		float: left;
		margin-right: 20px;
	}

	.index_three_content_l_two_list:hover {
		cursor: pointer;
	}

	.index_three_content_l_two_list:nth-child(2n) {
		margin-right: 0;
	}

	.index_three_content_l_two_list img {
		width: 280px;
		height: 280px;
		display: block;
		border-radius: 8px;
	}

	.index_three_content_l_two_list h3 {
		font-size: 20px;
		font-weight: bold;
		color: #111111;
		margin-top: 32px;
	}

	.index_three_content_l_two_list p {
		font-size: 16px;
		font-weight: 400;
		color: #999999;
		margin-top: 12px;
	}

	.index_four {
		width: 100%;
		overflow: hidden;
		background-color: #F9FAFA;
	}

	.index_four .index_two_logo {
		width: 197px;
		height: 98px;
	}

	.index_four_content {
		width: 1200px;
		overflow: hidden;
		margin: 0 auto 88px auto;
	}

	.index_four_content_l {
		width: 564px;
		overflow: hidden;
		float: left;
	}

	.index_four_content_l h1 {
		font-size: 40px;
		font-weight: bold;
		color: #111111;
	}

	.index_four_content_l p {
		font-size: 16px;
		font-weight: 400;
		color: #111111;
		margin-top: 24px;
	}

	.index_four_content_l_border {
		width: 104px;
		height: 6px;
		background: #1F86EC;
		margin-top: 25px;
		margin-bottom: 34px;
	}

	.index_four_content_l span {
		font-size: 16px;
		font-weight: 400;
		color: #666666;
		line-height: 32px;
	}

	.index_four_content_l_more {
		width: 184px;
		height: 40px;
		background: #1F86EC;
		border-radius: 4px 4px 4px 4px;
		text-align: center;
		line-height: 40px;
		margin-top: 135px;
	}

	.index_four_content_l_more:hover {
		cursor: pointer;
	}

	.index_four_content_l_more span {
		font-size: 14px;
		font-weight: 400;
		color: #FFFFFF;
	}

	.index_four_content_r {
		width: 549px;
		height: 530px;
		float: right;
	}

	.index_four_content_r img {
		width: 100%;
		height: 100%;
	}

	.index_five {
		width: 100%;
		height: auto;
		background-color: #fff;
	}

	.index_five .index_two_logo {
		width: 217px;
		height: 98px;
	}

	.index_five_content {
		width: 1200px;
		height: auto;
		margin: 0 auto 110px auto;
	}

	.index_five_l {
		width: 592px;
		height: 562px;
		background: #FFFFFF;
		box-shadow: 0px 4px 14px 1px rgba(0, 0, 0, 0.08);
		border-radius: 4px 4px 4px 4px;
		float: left;
	}

	.index_five_l_content {
		width: 562px;
		height: 462px;
		margin: 40px auto;
	}

	.index_five_l_content:hover {
		cursor: pointer;
	}

	.index_five_l_content_list {
		width: 100%;
		height: 100%;
	}

	.index_five_l_content_list_img {
		width: 100%;
		height: 240px;
		border-radius: 8px;
	}

	.index_five_l_content_list h3 {
		font-size: 22px;
		font-weight: bold;
		color: #111111;
		margin-top: 32px;
		margin-bottom: 24px;
	}

	.index_five_l_content_list span {
		font-size: 14px;
		font-weight: 400;
		color: #999999;
	}

	.index_five_l_content_list p {
		font-size: 16px;
		font-weight: 400;
		color: #666666;
		line-height: 26px;
		margin-top: 32px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		height: 40px;
	}

	.index_five_l_content_list_bottom {
		width: 100%;
		overflow: hidden;
		margin-top: 60px;
	}

	.index_five_l_content_list_bottom img {
		width: 38px;
		height: 12px;
		display: block;
		float: left;
	}

	.index_five_l_content_list_bottom_r {
		width: auto;
		height: 12px;
		float: right;
	}

	.index_five_l_content_list_bottom_r_list {
		width: 12px;
		height: 12px;
		background: #183884;
		border-radius: 6px 6px 6px 6px;
		float: right;
		margin-left: 15px;
		opacity: 0.3;
	}

	.index_five_l_content_list_bottom_r_list_now {
		background: #1F86EC;
		opacity: 0.9;
	}

	.index_five_r {
		width: 573px;
		height: auto;
		float: right;
	}

	.index_five_r_header {
		font-size: 28px;
		font-weight: 400;
		color: #111111;
		position: relative;
		margin-bottom: 24px;
	}

	.index_five_r_header_border {
		width: 443px;
		border: 1px dashed #0A488A;
		position: absolute;
		right: 0;
		top: 20px;
		opacity: 0.3;
	}

	.index_five_r_list {
		width: 573px;
		height: 170px;
		background: #FFFFFF;
		border-bottom: 1px solid #eee;
	}

	.index_five_r_list:hover {
		cursor: pointer;
	}

	.index_five_r_list:hover {
		box-shadow: 0px 3px 8px 1px rgba(52, 155, 232, 0.12);
	}

	.index_five_r_list:hover .index_five_r_list_l span {
		background-color: rgba(31, 134, 236, 0.2);
		color: #1F86EC;
	}

	.index_five_r_list:hover .index_five_r_list_l p {
		color: #1F86EC;
	}

	.index_five_r_list:hover .index_five_r_list_r p {
		color: #1F86EC;
		font-weight: bold;
	}

	.index_five_r_list_l {
		width: 100px;
		height: 100%;
		text-indent: 32px;
		float: left;
	}

	.index_five_r_list_l p {
		font-size: 22px;
		font-weight: bold;
		color: #111111;
		margin-top: 32px;
		margin-bottom: 24px;
	}

	.index_five_r_list_l span {
		width: auto;
		height: 20px;
		background: #BFBFBF;
		border-radius: 20px;
		font-size: 14px;
		font-weight: 400;
		color: #FFFFFF;
		text-align: center;
		margin-left: 32px;
		padding: 4px 8px;
	}

	.index_five_r_list_r {
		width: 466px;
		height: 100%;
		float: right;
	}

	.index_five_r_list_r p {
		font-size: 20px;
		font-weight: 400;
		color: #111111;
		line-height: 32px;
		margin-top: 32px;
		margin-bottom: 24px;
	}

	.index_five_r_list_r span {
		font-size: 16px;
		font-weight: 400;
		color: #666666;
		word-break: break-all;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		/* 这里是超出几行省略 */
		overflow: hidden;
	}

	.index_six {
		width: 100%;
		overflow: hidden;
		background-image: url("../assets/ay29.png");
		background-size: 100%;
	}

	.index_six .index_two_logo {
		width: 163px;
		height: 98px;
	}

	.index_six_content {
		width: 1200px;
		overflow: hidden;
		margin: 0 auto 40px auto;
	}

	.index_six_list {
		width: 128px;
		height: 80px;
		display: block;
		float: left;
		margin-right: 25px;
		margin-bottom: 32px;
		cursor: pointer;
	}

	.index_six_list:nth-child(8n) {
		margin-right: 0;
	}

	.index_six_more {
		width: 184px;
		height: 40px;
		background: #1F86EC;
		border-radius: 4px 4px 4px 4px;
		text-align: center;
		line-height: 40px;
		font-size: 14px;
		font-weight: 400;
		color: #FFFFFF;
		margin: 40px auto;
		cursor: pointer;
	}
</style>