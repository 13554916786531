import Vue from 'vue'
import VueRouter from 'vue-router';
import HomeView from '../views/HomeView.vue';
Vue.use(VueRouter)
const routes = [{
		path: '/',
		name: 'home',
		component: HomeView
	},
	// 关于我们
	{
		path: '/aboutus',
		name: 'aboutus',
		component: () => import( /* webpackChunkName: "about" */ '../views/aboutus.vue')
	},
	//联系我么
	{
		path: '/lianxiwome',
		name: 'lianxiwome',
		component: () => import( /* webpackChunkName: "about" */ '../views/lianxiwome.vue')
	},
	// 配方案例
	{
		path: '/peifanganli',
		name: 'peifanganli',
		component: () => import( /* webpackChunkName: "about" */ '../views/peifanganli.vue')
	},
	// 配方案例详情
	{
		path: '/peifanganlidetail',
		name: 'peifanganlidetail',
		component: () => import( /* webpackChunkName: "about" */ '../views/peifanganlidetail.vue')
	},
	// 新闻资讯
	{
		path: '/news',
		name: 'news',
		component: () => import( /* webpackChunkName: "about" */ '../views/news.vue')
	},
	// 资讯详情
	{
		path: '/newsdetail',
		name: 'newsdetail',
		component: () => import( /* webpackChunkName: "about" */ '../views/newsdetail.vue')
	},
	//跳转搜索
	{
		path: '/search',
		name: 'search',
		component: () => import( /* webpackChunkName: "about" */ '../views/search.vue')
	},
	//代理品牌
	{
		path: '/dailipingpai',
		name: 'dailipingpai',
		component: () => import( /* webpackChunkName: "about" */ '../views/dailipingpai.vue')
	},
	//代理品牌详情
	{
		path: '/dailipingpaidetail',
		name: 'dailipingpaidetail',
		component: () => import( /* webpackChunkName: "about" */ '../views/dailipingpaidetail.vue')
	},
	//产品中心
	{
		path: '/chanpinzhongxin',
		name: 'chanpinzhongxin',
		component: () => import( /* webpackChunkName: "about" */ '../views/chanpinzhongxin.vue')
	},
	
	
	
	
	
	
]

const router = new VueRouter({
	// mode: 'history',
	base: process.env.BASE_URL,
	routes
})
//跳转后返回页面顶部
router.afterEach(() => {
    window.scrollTo(0,0);
})

export default router
