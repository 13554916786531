<template>
	<div class="hello">
		<div class="login_header">
			<div class="login_header_content">
				<div class="login_header_one">
					<img class="login_header_one_log" :src="basedetail.web_site_logo" alt=""@click="gonext(1)" v-if="basedetail.web_site_logo">
					<div class="login_header_search">
						<div class="login_header_search_l" @click="opencearch">
							<span>{{searchtitle}}</span>
							<img src="../assets/ay2.png" alt="">
						</div>
						<div class="login_header_search_c">
							<input type="text" maxlength="50" placeholder="请输入搜索内容" v-model="keywords" @keyup.enter="gosearch" >
						</div>
						<img class="clear_btn" src="../assets/ay73.png" alt="" v-if="keywords !=''" @click="clearkeywords" />
						<div class="login_header_search_r" @click="gosearch">
							<img src="../assets/ay3.png" alt="">
						</div>
						<div class="search_check" v-if="searchshow">
							<div class="search_check_list" v-for="(item,index) in searchlist" @click="checksearch(item,index)" :key="index">
								<span>{{item}}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 正常的头部导航 -->
			<div class="login_header_two">
				<div class="login_header_two_content">
					<div class="login_header_two_right_list" :class="{'headercheck login_header_two_right_list_now':headerindex==1}"
						@click="gonext(1)">
						<span>首页</span>
						<img class="login_header_two_right_list_border" src="../assets/ay4.png" alt="" v-if="headerindex==1">
					</div>
					<div class="login_header_two_right_list" @click="gonext(2)" :class="{'headercheck login_header_two_right_list_now':headerindex==2}">
						<span>关于奥源</span>
						<img class="login_header_two_right_list_border" src="../assets/ay4.png" alt="" v-if="headerindex==2">
					</div>
					<div class="login_header_two_right_list"@click="gonext(3)" :class="{'headercheck login_header_two_right_list_now':headerindex==3}">
						<span>产品中心</span>
						<img class="login_header_two_right_list_border" src="../assets/ay4.png" alt="" v-if="headerindex==3">
					</div>
					<div class="login_header_two_right_list"@click="gonext(4)" :class="{'headercheck login_header_two_right_list_now':headerindex==4}">
						<span>配方案例</span>
						<img class="login_header_two_right_list_border" src="../assets/ay4.png" alt="" v-if="headerindex==4">
					</div>
					<div class="login_header_two_right_list"@click="gonext(5)" :class="{'headercheck login_header_two_right_list_now':headerindex==5}">
						<span>代理品牌</span>
						<img class="login_header_two_right_list_border" src="../assets/ay4.png" alt="" v-if="headerindex==5">
					</div>
					<div class="login_header_two_right_list"@click="gonext(6)" :class="{'headercheck login_header_two_right_list_now':headerindex==6}">
						<span>新闻资讯</span>
						<img class="login_header_two_right_list_border" src="../assets/ay4.png" alt="" v-if="headerindex==6">
					</div>
					<div class="login_header_two_right_list"@click="gonext(7)" :class="{'headercheck login_header_two_right_list_now':headerindex==7}">
						<span>联系我们</span>
						<img class="login_header_two_right_list_border" src="../assets/ay4.png" alt="" v-if="headerindex==7">
					</div>
					<div class="login_header_two_right_list"@click="gonext(8)" :class="{'headercheck login_header_two_right_list_now':headerindex==8}">
						<img class="login_header_two_right_list_shop" src="../assets/ay5.png" alt="">
						<span>商城</span>
						<img class="login_header_two_right_list_border" src="../assets/ay4.png" alt="" v-if="headerindex==8">
					</div>
				</div>
			</div>
		</div>
		<div style="width: 100%;height: 170px;"></div>
	</div>
</template>

<script>
	export default {
		name: 'HelloWorld',
		data() {
			return {
				headerindex: 1, //头部一级状态
				keywords: "", //搜索关键车
				searchshow:false,//选择搜索的弹窗
				searchtitle:"原料",//搜索的类型
				searchlist:["原料","配方","代理品牌","资讯"],//搜索选择的文字
				basedetail:"",//网站基本信息
			}
		},
		mounted() {
			if(localStorage.getItem("headerindex")){
				this.headerindex = localStorage.getItem("headerindex");
			}else{
				this.headerindex = 1;
			}
			if(localStorage.getItem("searchtitle")){
				this.searchtitle = localStorage.getItem("searchtitle");
			}else{
				this.searchtitle = "原料";
			}
			if(localStorage.getItem("keywords")){
				this.keywords = localStorage.getItem("keywords");
			}else{
				this.keywords = "";
			}
		},
		created() {
			this.linktab();
			this.get_base();//获取网站基本信息
		},
		methods: {
			linktab() {
				let goUrl = this.isMobile();
				if (goUrl === 1) {
					//移动端地址
					location = "https://www.a-owen.com/h5/#/";
				} else {
					//PC地址
					// location = "https://www.denlike.com/";
				}
			},
			isMobile() {
				let flag = navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				);
				let goUrl = flag ? 1 : 0;
				return goUrl;
			},
			
			//获取网站基本信息
			get_base(){
				this.$http({
					url: '64eff8c24ae20',
					method: 'post',
					data:JSON.stringify({
						
					})
				}).then((res) => {
					if (res.data.code == 1) {
						this.basedetail = res.data.data;
					}
				});
			},
			
			//删除搜索关键字
			clearkeywords(){
				this.keywords = "";
				localStorage.removeItem("keywords")
			},
			
			//打开搜索功能弹窗
			opencearch(){
				this.searchshow = !this.searchshow;
			},
			
			//选择搜索
			checksearch(name,index){
				this.searchtitle = name;
				localStorage.setItem("searchtitle",name);
				localStorage.setItem("searchtype",index);
				this.searchshow = false;
				let url = location.href.split("#/")[1];
				if(url=="search"){
					window.location.reload();
				}
			},
			
			//跳转搜索
			gosearch(){
				let url = location.href.split("#/")[1];
				if(url=="search"){
					this.$emit("sendData",this.keywords);
				}else{
					if(this.keywords==""){
					this.$message({
						message:"搜索关键词不能为空",
						type: "error"
					})
						return;
					}
					localStorage.setItem("keywords",this.keywords);
					this.$router.push({
						path: "/search"
					})
				}
			},
			
			//跳转下级页面(一级点击事件)
			gonext(type) {
				localStorage.setItem("headerindex",type);
				if (type == 1) {
					this.$router.push({
						path: "/"
					})
				} else if (type == 2) {
					this.$router.push({
						path: "/aboutus"
					})
				} else if (type == 3) {
					this.$router.push({
						path: "/chanpinzhongxin"
					})
				} else if (type == 4) {
					this.$router.push({
						path: "/peifanganli"
					})
				} else if (type == 5) {
					this.$router.push({
						path: "/dailipingpai"
					})
				} else if (type == 6) {
					this.$router.push({
						path: "/news"
					})
				} else if (type == 7) {
					this.$router.push({
						path: "/lianxiwome"
					})
				} else if (type == 8) {
					window.open("https://aowenshop.1688.com/?spm=a26352.b28411319.offerlist.10.6d731e62tqEcsh")
				}
			},

			//搜索点击事件
			searchclick() {
				var url = window.location.href;
				// if(url=="https://www.denlike.com/pcchina/#/shopIndex"){
				// 	this.$router.push({path:"shopSearch"})
				// }else{
				// 	this.$emit('change', this.keywords);
				// }
				this.$emit('change', this.keywords);
			},

			
		},
	}
</script>
<style scoped>
	.login_header {
		width: 100%;
		background: #fff;
		cursor: pointer;
		position: fixed;
		left: 0;
		top: 0;
		z-index: 999;
	}
	.login_header_content{
		width: 100%;
		height: 110px;
		border-bottom: 1px solid #EEEEEE;
	}
	.login_header_one {
		width: 1200px;
		height:110px;
		margin: 0 auto;
		color: #333333;
	}
	.login_header_one_log{
		width: 242px;
		height: 64px;
		margin-top: 23px;
		display: block;
		float: left;
	}
	.login_header_search{
		width: 412px;
		height: 48px;
		background: #FFFFFF;
		border-radius: 24px 24px 24px 24px;
		border: 1px solid #1F86EC;
		float:right;
		margin-top: 37px;
		position: relative;
	}
	.login_header_search_l{
		width: auto;
		height: 100%;
		font-size: 14px;
		font-weight: 400;
		color: #111111;
		line-height: 48px;
		margin-left: 24px;
		float: left;
	}
	.login_header_search_l img{
		width: 10px;
		height: 6px;
		padding-left: 10px;
	}
	.login_header_search_c{
		width: 50%;
		height: 100%;
		float: left;
		margin-left: 24px;
	}
	.login_header_search_c input{
		width: 100%;
		height: 100%;
		font-size: 14px;
		font-weight: 400;
		color: #333;
		border: none;
		outline: none;
	}
	.login_header_search_r{
		width: 52px;
		height: 40px;
		background: #1F86EC;
		border-radius: 20px 20px 20px 20px;
		float:right;
		margin-top: 4px;
		margin-right: 4px;
	}
	.login_header_search_r img{
		width: 20px;
		height: 20px;
		display: block;
		margin: 10px auto;
	}
	
	.login_header_two {
		width: 100%;
		height: 60px;
		background-color: #fff;
		border-bottom: 1px solid #eee;
	}

	.login_header_two_content {
		width: 1200px;
		height: 60px;
		margin: 0 auto;
	}
	.login_header_two_right_list{
		width: auto;
		height:100%;
		font-size: 16px;
		font-weight: 400;
		color: #111111;
		float: left;
		margin-right: 86px;
		line-height: 60px;
		position: relative;
		text-align: center;
	}
	.login_header_two_right_list_now{
		font-weight: bold;
		font-size: 16px;
	}
	.login_header_two_right_list_border{
		width: 49px;
		height: 4px;
		display: block;
		position: relative;
		bottom: 4px;
		margin: 0 auto;
	}
	.login_header_two_right_list_shop{
		width: 16px;
		height: 18px;
		position: relative;
		top: 3px;
		margin-right: 4px;
	}
	.search_check{
		width: 200px;
		overflow: hidden;
		background-color: #fff;
		box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.16);
		border-radius: 8px 8px 8px 8px;
		z-index: 999;
		position: relative;
		margin-left: 24px;
		top: 12px;
		cursor: pointer;
	}
	.search_check_list{
		width: 100%;
		height: 52px;
		line-height:52px;
		text-indent: 32px;
		font-size: 16px;
		cursor: pointer;
	}
	.clear_btn{
		width: 18px;
		height: 18px;
		position: absolute;
		top:16px;
	}
</style>
