<template>
	<div class="fotter">
		<div class="fotter_content">
			<div class="fotter_one">
				<img class="fotter_one_log" src="../assets/ay33.png" alt="">
				<div class="fotter_one_list">
					<span>*</span>
					<input type="text" maxlength="20" placeholder="您的公司（必填）" v-model="company">
				</div>
				<div class="fotter_one_list">
					<span>*</span>
					<input type="text" maxlength="20" placeholder="您的昵称（必填）" v-model="peoplename">
				</div>
				<div class="fotter_one_list">
					<span>*</span>
					<input type="text" onkeyup="value=value.replace(/[\D]/g,'')" maxlength="11" placeholder="您的手机（必填）" v-model="mobile">
				</div>
				<div class="fotter_one_list_two">
					<span>*</span>
					<textarea maxlength="100" name="" id="" cols="30" rows="10" placeholder="咨询内容（必填）" v-model="answertext"></textarea>
				</div>
				<div class="fotter_one_submit" @click="shenqinsubmit">
					<span>提交</span>
				</div>
			</div>
			<div class="fotter_two">
				<img class="fotter_one_log" src="../assets/ay34.png" alt="">
				<div class="fotter_two_list">
					<p>{{basedetail.company1}}</p>
					<p>电话: {{basedetail.company1_tel}}</p>
					<p>地址: {{basedetail.company1_addr}}</p>
				</div>
				<div class="fotter_two_list">
					<p>{{basedetail.company2}}</p>
					<p>电话: {{basedetail.company2_tel}}</p>
					<p>地址: {{basedetail.company2_addr}}</p>
				</div>
				<div class="fotter_two_list" v-if="basedetail.company3">
					<p>{{basedetail.company3}}</p>
					<p>电话: {{basedetail.company3_tel}}</p>
					<p>地址: {{basedetail.company3_addr}}</p>
				</div>
				<div class="fotter_two_list" v-if="basedetail.company4">
					<p>{{basedetail.company4}}</p>
					<p>电话: {{basedetail.company4_tel}}</p>
					<p>地址: {{basedetail.company4_addr}}</p>
				</div>
			</div>
			<div class="fotter_three">
				<img class="fotter_one_log" src="../assets/ay74.png" alt="">
				<div class="fotter_three_list">
					<ul>
						<li @click="gonext(1)">首页</li>
						<li @click="gonext(2)">关于奥源</li>
						<li @click="gonext(6)">新闻资讯</li>
						<li @click="gonext(3)">产品中心</li>
						<li @click="gonext(4)">配方案例</li>
						<li @click="gonext(5)">代理品牌</li>
						<li @click="gonext(7)">联系我们</li>
						<li @click="gonext(8)">商城</li>
					</ul>
				</div>
			</div>
			<div class="fotter_four">
				<img class="fotter_one_log" src="../assets/ay75.png" alt="">
				<div class="fotter_four_content">
					<img class="fotter_four_code" :src="basedetail.weixin_scan" alt="">
				</div>
			</div>
		</div>
		<div class="fotter_bottom">
			<span>版权所有 © {{basedetail.web_site_copyright}}</span>
		</div>
		<div class="fotter_five" @click="liuyanshow= true">
			<img src="../assets/54.png" alt="">
		</div>
		<div class="shenqing"  v-if="liuyanshow">
			<div class="shenqing_content">
				<div class="shenqing_content_header">
					<img class="shenqing_content_header_l" src="../assets/ay71.png" alt="">
					<span>我要留言</span>
					<img class="shenqing_content_header_r" src="../assets/ay73.png" alt="" @click="liuyanshow = false">
				</div>
				<div class="fotter_one_list">
					<span>*</span>
					<p>公司名称</p>
					<input type="text" maxlength="20" placeholder="您的公司（必填）" v-model="company">
				</div>
				<div class="fotter_one_list">
					<span>*</span>
					<p>您的名字</p>
					<input type="text" maxlength="20" placeholder="您的昵称（必填）" v-model="peoplename">
				</div>
				<div class="fotter_one_list">
					<span>*</span>
					<p>您的手机</p>
					<input type="text" onkeyup="value=value.replace(/[\D]/g,'')" placeholder="您的手机（必填）" v-model="mobile">
				</div>
				<div class="fotter_one_list_two">
					<p style="margin-left: 30px;margin-top:10px;">咨询内容</p>
					<textarea  name="" id="" cols="30" rows="10" placeholder="咨询内容" v-model="answertext"></textarea>
				</div>
				<div class="fotter_one_submit" @click="shenqinsubmit">
					<span>提交</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		data() {
			return{
				company:"",//公司昵称
				peoplename:"",//昵称
				mobile:"",//电话
				answertext:"",//咨询内容
				basedetail:"",//网站基本信息
				yszcxyshow: false, //隐私政策协议弹窗
				xieyicontent:"",//协议详情数据
				opentype: 1, //区别注册和隐私协议
				oneurl:"",//获取的当前页面路径
				liuyanshow:false,//留言门板状态
			}
		},
		mounted() {
			this.oneurl = window.location.href;
			this.get_base();//获取底部基础信息
		},
		methods:{
			//申请提交事件
			shenqinsubmit(){
				let _this = this;
				var testphone = /^1\d{10}$/;
				if(_this.company==""){
					this.$message({
						message:"请填写公司名称",
						type: "error"
					})
					return;
				}
				if(_this.peoplename==""){
					this.$message({
						message:"请填写昵称",
						type: "error"
					})
					return;
				}
				if(!testphone.test(_this.mobile)){
					this.$message({
						message:"请填写手机",
						type: "error"
					})
					return;
				}
				if(_this.answertext == ""){
					this.$message({
						message:"请填写咨询内容",
						type: "error"
					})
					return;
				}
				this.set_answers();
			},
			
			//留言
			set_answers(){
				this.$http({
					url: '647d9daab1a66',
					method: 'post',
					data:JSON.stringify({
						wenben_15355:this.company,
						wenben:this.peoplename,
						wenben_15266:this.mobile,
						neirong:this.answertext,
					})
				}).then((res) => {
					if (res.data.code == 1) {
						this.$message({
							message:"提交成功",
							type: "success"
						})
						this.company = "";
						this.peoplename = "";
						this.mobile = "";
						this.answertext = "";
						this.liuyanshow = false;
					}else{
						this.$message({
							message:res.data.msg,
							type: "error"
						})
					}
				});
			},
			
			//获取网站基本信息
			get_base(){
				this.$http({
					url: '64eff8c24ae20',
					method: 'post',
					data:JSON.stringify({
						
					})
				}).then((res) => {
					if (res.data.code == 1) {
						this.basedetail = res.data.data;
					}
				});
			},
			
			//跳转下级页面(一级点击事件)
			gonext(type) {
				localStorage.setItem("headerindex",type);
				if (type == 1) {
					this.$router.push({
						path: "/"
					})
				} else if (type == 2) {
					this.$router.push({
						path: "/aboutus"
					})
				} else if (type == 3) {
					this.$router.push({
						path: "/chanpinzhongxin"
					})
				} else if (type == 4) {
					this.$router.push({
						path: "/peifanganli"
					})
				} else if (type == 5) {
					this.$router.push({
						path: "/dailipingpai"
					})
				} else if (type == 6) {
					this.$router.push({
						path: "/news"
					})
				} else if (type == 7) {
					this.$router.push({
						path: "/lianxiwome"
					})
				} else if (type == 8) {
					
				}
			},
		}
	}
</script>

<style scoped>
	.fotter{
		width: 100%;
		overflow: hidden;
		background: #1E1F20;
	}
	.fotter_content{
		width: 1200px;
		overflow: hidden;
		margin: 0 auto;
		cursor: pointer;
	}
	.fotter_one{
		width: 360px;
		overflow: hidden;
		float: left;
		margin-top: 58px;
	}
	.fotter_one_log{
		width: 145px;
		height: 46px;
		margin-bottom: 24px;
	}
	.fotter_one_list{
		width: 360px;
		height: 40px;
		background:rgba(255, 255, 255, 0.1);
		border-radius: 8px 8px 8px 8px;
		line-height: 40px;
		margin-bottom: 12px;
	}
	.fotter_one_list span{
		font-size: 14px;
		font-weight: 400;
		color: #EA4025;
		padding-left: 12px;
		padding-right: 12px;
	}
	.fotter_one_list input{
		width: 90%;
		height: 100%;
		font-size: 14px;
		font-weight: 400;
		font-size: 14px;
		font-weight: 400;
		color: #FFFFFF;
		background:rgba(255, 255, 255, 0.0);
		border: none;
		outline: none;
		text-indent: 10px;
	}
	.fotter_one_list_two{
		width: 360px;
		height: 73px;
		background:rgba(255, 255, 255, 0.1);
		border-radius: 8px 8px 8px 8px;
		margin-bottom: 12px;
		position: relative;
	}
	.fotter_one_list_two span{
		font-size: 14px;
		font-weight: 400;
		color: #EA4025;
		padding-left: 12px;
		padding-right: 12px;
		position: relative;
		top: -40px;
	}
	.fotter_one_list_two textarea{
		width: 90%;
		height: 90%;
		font-size: 14px;
		font-weight: 400;
		font-size: 14px;
		font-weight: 400;
		color: #FFFFFF;
		background:rgba(255, 255, 255, 0.0);
		border: none;
		outline: none;
		text-indent: 10px;
		resize: none;
		line-height:40px;
	}
	.fotter_one_submit{
		width: 128px;
		height: 40px;
		background: #1F86EC;
		border-radius: 4px 4px 4px 4px;
		font-size: 14px;
		font-weight: 400;
		color: #FFFFFF;
		text-align: center;
		line-height: 40px;
	}
	.fotter_two{
		width: 350px;
		overflow: hidden;
		float: left;
		margin-top: 58px;
		margin-left: 30px;
	}
	.fotter_two .fotter_one_log{
		width: 143px;
		height: 46px;
		margin-bottom: 24px;
	}
	.fotter_two_list{
		width: 100%;
		overflow: hidden;
		margin-bottom: 18px;
	}
	.fotter_two_list p{
		font-size: 14px;
		font-weight: 400;
		color:rgba(255, 255, 255, 0.5);
		line-height: 25px;
	}
	.fotter_three{
		width: 107px;
		height: auto;
		float: left;
		margin-left: 30px;
		margin-top: 58px;
	}
	.fotter_three .fotter_one_log{
		width: 107px;
		width: 46pxpx;
		margin-bottom: 24px;
	}
	.fotter_three_list{
		width: 100%;
		height: auto;
		color:rgba(255, 255, 255, 0.5);
		font-size: 14px;
		font-weight: 400;
		line-height: 30px;
	}
	.fotter_four{
		width: 280px;
		overflow: hidden;
		float: right;
		margin-top: 58px;
	}
	.fotter_four .fotter_one_log{
		width: 146px;
		height: 46px;
		overflow: hidden;
	}
	.fotter_four_content{
		width: 100%;
		overflow:hidden;
		position: relative;
	}
	.fotter_four_content_bg{
		width: 100%;
		height: 100%;
	}
	.fotter_four_code{
		width: 280px;
		height: 280px;
	}
	.fotter_bottom{
		width: 100%;
		hheight: 64px;
		background: rgba(255,255,255,0.2);
		font-size: 14px;
		font-weight: 400;
		color: #999999;
		line-height: 64px;
		text-align: center;
		margin-top: 50px;
	}
	.fotter_five{
		width: 100px;
		height: 100px;
		position: fixed;
		right:100px;
		bottom: 100px;
		z-index: 9;
		cursor: pointer;
	}
	.fotter_five img{
		width: 100%;
		height: 100%;
	}
	.footer_six{
		width: 100%;
		height: 100%;
		position: fixed;
		background-color: rgba(0, 0, 0, 0.5);
		left: 0;
		top: 0;
		z-index: 9;
	}
	.footer_six_mb{
		width: 460px;
		overflow: hidden;
		margin: 200px auto;
		background-color: #FFFFFF;
		border-radius: 12px;
	}
	.footer_six_mb_header{
		width: 460px;
		color: #333;
		font-size: 24px;
		text-align: center;
		line-height: 48px;
		position: relative;
	}
	.footer_six_mb_header img{
		width: 20px;
		height: 20px;
		position: absolute;
		right: 10px;
		top: 10px;
		cursor: pointer;
	}
	.footer_six_mb .fotter_one_list{
		margin-left: 50px;
	}
	.footer_six_mb .fotter_one_list_two{
		margin-left: 50px;
	}
	.footer_six_mb input{
		background-color:rgba(0, 0, 0, 0.3);
	}
	.footer_six_mb textarea{
		background-color:rgba(0, 0, 0, 0.3);
	}
	.footer_six_mb .fotter_one_submit{
		margin-left: 140px;
		margin-bottom: 30px;
		cursor: pointer;
	}
	.shenqing{
		width:100%;
		height: 100%;
		position: fixed;
		left: 0;
		top:0;
		background-color: rgba(0, 0, 0, 0.8);
		z-index: 999;
	}
	.shenqing_content{
		width: 480px;
		height: 519px;
		background: #FFFFFF;
		border-radius: 8px 8px 8px 8px;
		margin: 80px auto;
	}
	.shenqing_content_header{
		width: 480px;
		height: 64px;
		background:rgba(31,134,236,0.1);
		border-radius: 8px 8px 0px 0px;
		font-size: 18px;
		font-weight: bold;
		color: #333333;
		line-height: 64px;
	}
	.shenqing_content_header_l{
		width: 4px;
		height: 18px;
		margin-left: 24px;
		position: relative;
		top:3px;
		margin-right: 10px;
	}
	.shenqing_content_header_r{
		width: 16px;
		height: 16px;
		position: relative;
		left: 320px;
		cursor: pointer;
	}
	.shenqing {
		.fotter_one_list{
		width: 430px;
		height: 40px;
		border-radius: 8px 8px 8px 8px;
		line-height: 40px;
		margin-top: 24px;
		margin-left: 25px;
		display: flex;
		font-size: 16px;
		font-weight: 400;
		color: #333333;
	}
	.fotter_one_list span{
		font-size: 14px;
		font-weight: 400;
		color: #EA4025;
		padding-left: 12px;
		padding-right: 12px;
	}
	.fotter_one_list input{
		width: 70%;
		height: 100%;
		font-size: 14px;
		font-weight: 400;
		font-size: 14px;
		font-weight: 400;
		color: #bfbfbf;
		border: 1px solid #eee;
		border-radius: 4px;
		outline: none;
		text-indent: 10px;
		margin-left: 24px;
	}
	.fotter_one_list_two{
		width: 430px;
		height: 73px;
		border-radius: 8px 8px 8px 8px;
		margin-bottom: 12px;
		position: relative;
		margin-left: 25px;
		display: flex;
		font-size: 16px;
		font-weight: 400;
		color: #333333;
		margin-top: 24px;
	}
	.fotter_one_list_two textarea{
		width: 70%;
		height: 90%;
		font-size: 14px;
		font-weight: 400;
		font-size: 14px;
		font-weight: 400;
		color: #bfbfbf;
		outline: none;
		text-indent: 10px;
		border: 1px solid #eee;
		border-radius: 4px;
		margin-left: 24px;
		resize: none;
	}
	.fotter_one_submit{
		width: 184px;
		height: 40px;
		background: #1F86EC;
		border-radius: 4px 4px 4px 4px;
		font-size: 14px;
		font-weight: 400;
		color: #FFFFFF;
		text-align: center;
		line-height: 40px;
		margin: 32px auto;
		cursor: pointer;
	}
	}
</style>